import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import Back from "../../components/back";
import ListItem from "../../components/list/listItem";
import { useNavigate, useParams } from "react-router";
import { ToastContent, toast } from "react-toastify";
import Button from "../../components/button";
import {
  getUserChannels,
  reset,
} from "../../features/mediationCases/channelsSlice";
import classNames from "classnames";
import Pagnition from "../../components/pagnition";
import Spinner from "../../components/spinner/spinner";

type ViewId = "all" | "active" | "closed";
const viewIds = ["all", "active", "closed"];

const ViewRequests = () => {
  const { viewId } = useParams<{ viewId: ViewId }>();
  const { channels, isLoading } = useAppSelector((state) => state.channels);
  const { channels: filteredChannels, page, count, totalPages } = channels;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryParams = viewId === "all" ? {} : { status: viewId };

  useEffect(() => {
    dispatch(getUserChannels(queryParams));
    return () => {
      dispatch(reset());
    };
  }, [viewId, navigate, dispatch]);

  function handlePagnition(direction: string) {
    const queryParams = {
      status: viewId,
      page: direction === "next" ? page + 1 : page - 1,
    };
    console.log(queryParams);
    dispatch(getUserChannels(queryParams));
  }

  return (
    <div className='my-4'>
      <div className='flex gap-6 sm:gap-10 items-center'>
        <div>
          <Back />
        </div>
        <p className='font-bold'>
          <span className='capitalize'>{viewId}</span> Requests
        </p>
      </div>
      {filteredChannels?.length ? (
        <div className='flex flex-col flex-wrap justify-between lg:flex-row gap-4 sm:gap-8 my-4 mb-4'>
          {isLoading ? (
            <div className='flex justify-center items-center w-full'>
              <Spinner />
            </div>
          ) : (
            filteredChannels?.map((channel: any) => (
              <ListItem
                key={channel?.caseID}
                caseID={channel?.caseID}
                reqTitle={channel?.title}
                status={channel?.status}
                createdAt={channel?.createdAt}
                chatID={channel?._id}
                from='/requests'
              />
            ))
          )}
        </div>
      ) : (
        <div className='flex justify-center my-10 py-4 sm:py-8 '>
          <div className='flex flex-col gap-4 w-[80%] sm:w-[60%] md:w-[40%]'>
            <p className='text-center'>
              You have no {viewId !== "all" && viewId} requests
            </p>
            <Button
              href='/requests/create'
              variant='primary'
              size='lg'
              className='w-[100%]'>
              Create New Request
            </Button>
          </div>
        </div>
      )}
      {totalPages > 1 && (
        <Pagnition
          page={page}
          totalPages={totalPages}
          otherParams={queryParams}
          dispatch={dispatch}
          getUserChannels={getUserChannels}
        />
      )}
    </div>
  );
};

export default ViewRequests;
