import React, { useEffect } from "react";
import { useDispatch, TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "../app/store";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { authenticateUser, reset } from "../features/auth/authSlice";
import { useAppSelector, useAppDispatch } from "../app/hook";
import { useNavigate } from "react-router";
import { ToastContent, toast } from "react-toastify";
import Spinner from "../components/spinner/spinner";

const Authentication = () => {
  const sessionId = new URLSearchParams(document.location.search).get(
    "sessionId"
  );
  const { user, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("ran");
    if (!sessionId) {
      redirect401();
    } else {
      dispatch(authenticateUser(sessionId));
    }
  }, []);

  useEffect(() => {
    if (isSuccess && message) {
      toast.success(message as ToastContent);
      navigate("/dashboard");
    }
    if (isError && message) {
      toast.error(message as ToastContent);
      navigate("/401");
    }
    if (isSuccess && user) {
      navigate("/dashboard");
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, dispatch, navigate]);

  function redirect401() {
    navigate("/401");
    localStorage.removeItem("token");
  }

  return (
    <div className='flex justify-center items-center h-[50vh] my-12 text-2xl'>
      <Spinner />
    </div>
  );
};

export default Authentication;
