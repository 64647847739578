import React, { useCallback, useEffect, useMemo, useState } from "react";
import ChatNavbar from "../../components/chatNavbar";
import avatar from "../../assets/images/chat_avatar.png";
import send from "../../assets/images/send.png";
import Back from "../../components/back";
import Chat from "./chat";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { Control, Controller, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import Textarea from "../../components/textarea";
import { getChat, sendChat } from "../../features/chat/chatSlice";
import {
  getUserChannels,
  reset,
} from "../../features/mediationCases/channelsSlice";
import RichTextEditor from "../../components/richTextEditor";
import classNames from "classnames";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "link",
  "image",
];

interface FormValues {
  channelId: string;
  message: string;
  attachment: string;
}

const ChatContainer = () => {
  const [editorHtml, setEditorHtml] = useState("");
  const { id } = useParams();
  const { channels } = useAppSelector((state) => state.channels);
  const { chat } = useAppSelector((state) => state.chat);
  const channel: any = channels.channels.find(
    (channel, index) => channel._id === id
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getChat(id));
  }, []);

  const form = useForm<FormValues>({
    defaultValues: {
      channelId: id,
      message: "",
      attachment: "",
    },
  });

  const { register, control, handleSubmit, watch, setValue, getValues, reset } =
    form;

  function onSubmit(data: FormValues) {
    // console.log(data);
    dispatch(sendChat(data));
    dispatch(getChat(id));
    reset();
  }

  const handleChange = (html: any) => {
    setEditorHtml(html);
  };

  return (
    <>
      <div className='h-svh flex flex-col '>
        <ChatNavbar />
        <div className=''>
          <div className='max-w-screen-xl mx-auto bg-[#F8F8F8] px-4'>
            <Back />
          </div>
        </div>

        {/* Content for chat area */}
        <Chat channel={channel} chat={chat} />

        {/* Input area */}
        <div className='bg-[#FFFFFF] p-4'>
          <div className='max-w-screen-xl mx-auto px-4'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ minHeight: "min-content" }}
              className='flex flex-1 gap-2 items-center'>
              <Controller
                name='message'
                control={control}
                rules={{}}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  // <RichTextEditor value={{ __html: value }} onChange={onChange} />
                  <Textarea
                    label=''
                    value={value}
                    className='w-full flex-1 h-full overflow-autos'
                    placeholder='Write your message here...'
                    onChange={onChange}
                    error={error?.message}
                    readOnly={!chat?.userRole?.isResponseAble} // not this condition for appropriate action
                  />
                )}
              />
              <button
                disabled={!chat?.userRole?.isResponseAble}
                className={classNames(
                  `bg-[#000000] text-[#ffffff] p-2 px-4 md:px-8 rounded-lg flex items-center gap-1`,
                  {
                    "opacity-50": !chat?.userRole?.isResponseAble,
                  }
                )}>
                <span className='font-bold text-xs sm:text-sm'>Send</span>
                <img src={send} alt='send' className='w-4 sm:w-8 pr-1' />
              </button>
            </form>
          </div>
        </div>
        <DevTool control={control} />
      </div>
    </>
  );
};

export default ChatContainer;
