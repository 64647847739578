import { useCallback, useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDropzone } from "react-dropzone";
import { Modal } from "antd";
import { ToastContent, toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import uploadFile from "../../assets/images/upload-files.png";
import Back from "../../components/back";
import Input from "../../components/input";
import Select from "../../components/select";
import Textarea from "../../components/textarea";
import Button from "../../components/button";
import useFileUpload from "../../hooks/use-upload";
import {
  createChannel,
  reset,
} from "../../features/mediationCases/channelsSlice";
import { DevTool } from "@hookform/devtools";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

interface FormValues {
  title: string;
  issue: string;
  platform: string;
  role: string;
  transactionNumber: string;
  description: string;
  attachment: {
    url: string;
  }[];
}

const issues: Record<string, string> = {
  disabled: "Select an option",
  "No delivery": "No delivery",
  "Bad goods/services": "Bad goods/services",
  "Unapproved payment": "Unapproved payment",
  Others: "Others",
};

const platforms: Record<string, string> = {
  disabled: "Select an option",
  vendpal: "VendPal",
  vendstash: "VendStash",
};

const roles: Record<string, Record<string, string>> = {
  vendstash: {
    disabled: "Select an option",
    merchant: "Merchant",
    buyer: "Buyer",
  },
  vendpal: {
    disabled: "Select an option",
    vendor: "Vendor",
    procuringCompany: "Procuring Company",
  },
};

const CreateRequest = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const { uploadFiles } = useFileUpload("/upload");
  const { channels, isError, isSuccess, message, error } = useAppSelector(
    (state) => state.channels
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const form = useForm<FormValues>({
    defaultValues: {
      title: "",
      issue: "disabled",
      platform: "disabled",
      role: "disabled",
      transactionNumber: "",
      description: "",
      attachment: [],
    },
  });

  const { register, control, handleSubmit, watch, setValue, getValues } = form;
  const { platform, attachment } = watch();

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isSuccess && message) {
      toast.success(message as ToastContent);
      dispatch(reset());
      navigate(`/request/details/${channels.channels[0]._id}`);
    }
    if (isError && error) {
      toast.error(error as ToastContent);
      dispatch(reset());
    }
  }, [isSuccess, isError, message, navigate]);

  const { fields, append, prepend, remove, swap, move, insert } =
    useFieldArray<FormValues>({
      control,
      name: "attachment",
    });

  const onDrop = useCallback(async (acceptedFiles: Array<File>) => {
    const response = await uploadFiles(acceptedFiles);
    const newImageUrls = response.data?.map((data) => ({ url: data }));
    if (newImageUrls)
      setValue("attachment", [...getValues("attachment"), ...newImageUrls]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function onSubmit(data: FormValues) {
    setIsModalVisible(true);
  }

  function handleConfirm() {
    const data = control._formValues;
    const newFormData = {
      title: data.title,
      issue: data.issue,
      platform: data.platform,
      role: data.role,
      transactionNumber: data.transactionNumber,
      description: data.description,
      attachment: data.attachment.map((data: any) => data.url),
    };
    // console.log(newFormData);
    dispatch(createChannel(newFormData));
    setIsModalVisible(false);
  }

  function handleFileChange() {
    alert();
  }

  function toggleSignoutModal() {
    setIsModalVisible(!setIsModalVisible);
  }

  return (
    <div className='my-4'>
      <div className='flex gap-6 sm:gap-10  items-center'>
        <div>
          <Back />
        </div>
      </div>
      <div className='flex sm:justify-center'>
        <div className='flex flex-col gap-2 sm:gap-4 justify-items-start sm:items-center'>
          <p className='font-bold'>Request Form</p>
          <p className='font-normal'>Please fill out details correctly</p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className='flex flex-col flex-wrap justify-between gap-y-6 md:flex-row mt-4'>
          <div className='md:w-[47%]'>
            <Controller
              name='title'
              control={control}
              rules={{
                required: { value: true, message: "A valid title is required" },
                pattern: {
                  value: /[A-Za-z]{3}/,
                  message: "Title must have at least 3 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Input
                  label='Title'
                  type='text'
                  className='w-full'
                  placeholder='Enter a title'
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <div className='md:w-[47%]'>
            <Controller
              name='issue'
              control={control}
              rules={{
                required: { value: true, message: "Select an option" },
                validate: {
                  notDisabled: (fieldValue) => {
                    return (
                      fieldValue !== "disabled" || "Please select an issue"
                    );
                  },
                },
              }}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <Select
                  label='Issue'
                  name='issue'
                  className='w-full'
                  options={Object.entries(issues).map((issue) => {
                    const [value, label] = issue;
                    return {
                      value,
                      label,
                    };
                  })}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <div className='md:w-[47%]'>
            <Controller
              name='platform'
              control={control}
              rules={{
                required: { value: true, message: "Select an option" },
                validate: {
                  notDisabled: (fieldValue) => {
                    return (
                      fieldValue !== "disabled" || "Please select a platform"
                    );
                  },
                },
              }}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <Select
                  label='Platform'
                  name='platform'
                  className='w-full'
                  options={Object.entries(platforms).map((platform) => {
                    const [value, label] = platform;
                    return {
                      value,
                      label,
                    };
                  })}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <div className='md:w-[47%]'>
            <Controller
              name='role'
              control={control}
              rules={{
                required: { value: true, message: "Select an option" },
                validate: {
                  notDisabled: (fieldValue) => {
                    return (
                      fieldValue !== "disabled" || "Please select an option"
                    );
                  },
                },
              }}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <Select
                  label='Role'
                  name='role'
                  className='w-full'
                  options={Object.entries(
                    platform && platform !== "disabled"
                      ? roles[platform.toLowerCase()]
                      : { disabled: "Select an option" }
                  ).map((role) => {
                    const [value, label] = role;
                    return {
                      value,
                      label,
                    };
                  })}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <div className='md:w-[47%]'>
            <Controller
              name='transactionNumber'
              control={control}
              rules={{
                required: { value: true, message: "A valid title is required" },
                pattern: {
                  value: /[A-Za-z0-9]{3}/,
                  message: "Min. of 3 characters expected",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Input
                  label='Order/transaction no'
                  type='text'
                  className='w-full'
                  placeholder='e.g. #SFDF0348'
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <div className='md:w-[100%]'>
            <Controller
              name='description'
              control={control}
              rules={{
                required: { value: true, message: "A description is required" },
                minLength: {
                  value: 10,
                  message: "Miniumum of 10 characters required",
                },
                maxLength: {
                  value: 500,
                  message: "Please keep your description under 500 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Textarea
                  label='Description'
                  className='w-full'
                  placeholder='Briefly describe the issue you may have with this transaction'
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <div className='md:w-[100%] text-center '>
            <Controller
              name='attachment'
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <div className='flex  border border solid p-5 justify-center border border-[#00000033] rounded-lg'>
                  <div {...getRootProps()}>
                    <label
                      htmlFor='imageInput'
                      className='flex flex-col gap-2 items-center cursor-pointer'>
                      <input
                        onChange={handleFileChange}
                        {...getInputProps()}
                        style={{ display: "none" }}
                      />
                      <img src={uploadFile} className='w-[100px] h-[108px]' />
                      <p className='text-xs sm:text-base'>
                        Drag & drop files here or attach file
                      </p>
                    </label>
                  </div>
                </div>
              )}
            />
            {attachment?.length > 0 && (
              <div className='w-[100%] max-w-[90dvw] flex flex-col gap-2 my-5 overflow-x-auto'>
                <div className='flex justify-between'>
                  <p className='text-sm'>Media</p>
                  <p
                    className='text-sm mx-2 hover:cursor-pointer'
                    onClick={() => {
                      setImageIndex(0);
                      setImageModalVisible(true);
                    }}>
                    View all
                  </p>
                </div>
                <div className='flex justify-center items-center gap-3 overflow-x-auto scroll-smooth p-2 rounded-lg  border border-[#00000033]'>
                  {attachment?.map(
                    (attachement: Record<string, string>, index: number) => (
                      <div
                        className='w-36 flex items-center hover:cursor-pointer'
                        key={index}
                        onClick={() => {
                          setImageIndex(index);
                          setImageModalVisible(true);
                        }}>
                        <img
                          src={attachement?.url}
                          className='w-24 h- max-h-24 max-w-24'
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>

          <div className='w-[100%] flex justify-center'>
            <div className='w-[70%] sm:w-[50%] md:w-[30%]'>
              <Button size='lg' variant='primary' className='w-full'>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>

      <Modal
        width={"85dvw"}
        styles={{ body: { height: "80dvh" } }}
        open={imageModalVisible}
        onCancel={() => setImageModalVisible(false)}
        className='relative top-10'
        footer={null}>
        {attachment?.length > 0 && (
          <div className='w-[100%] h-[100%] my-5 flex justify-between'>
            {imageIndex > 0 ? (
              <div
                className='flex items-center px-1 hover:cursor-pointer hover:bg-[#0000002F]'
                onClick={() => {
                  setImageIndex(imageIndex - 1);
                }}>
                <ArrowLeft2 size='32' color='#000000' />
              </div>
            ) : (
              <div></div>
            )}
            <div className='flex grow justify-center items-center gap-3 overflow-x-auto scroll-smooth p-2 rounded-lg'>
              <img src={attachment[imageIndex].url} />
            </div>
            {imageIndex < attachment.length - 1 && (
              <div
                className='flex items-center px-1 hover:cursor-pointer hover:bg-[#0000002F]'
                onClick={() => {
                  setImageIndex(imageIndex + 1);
                }}>
                <ArrowRight2 size='32' color='#000000' />
              </div>
            )}
          </div>
        )}
      </Modal>

      {/* CREATE REQUEST CONFIRMATION DIALOG */}
      <Modal
        width={730}
        open={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        className='flex justify-center px-auto mt-24 font-mont'>
        <div className='my-4 md:my-8 p-4 flex justify-center items-center mx-auto flex-col gap-6'>
          <h1 className='text-lg md:text-2xl text-[#040821] font-semibold'>
            Submit form?
          </h1>
          <p className='text-base md:text-xl text-center px-4'>
            You cannot edit form or upload media until a mediator responds
          </p>

          <div className='mt-2 flex gap-6 justify-center w-full md:px-8'>
            <Button
              size='md'
              variant='cancel'
              onClick={() => setIsModalVisible(!setIsModalVisible)}
              className='grow'>
              Cancel
            </Button>
            <Button
              size='md'
              variant='primary'
              onClick={handleConfirm}
              className='grow'>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <DevTool control={control} />
    </div>
  );
};

export default CreateRequest;
