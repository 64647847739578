import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import authService from "./authService";
import type { RootState } from "../../app/store";

interface User {
  token: string;
  user: {
    id: string;
    email: string;
    fullName: string;
    profilePhoto: string;
    platform: string;
  };
}

interface SliceState {
  user: User | any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string | unknown;
}

const initialState: SliceState = {
  user: { token: null, user: {} },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
} as SliceState;

// Authenticate a user
export const authenticateUser = createAsyncThunk(
  "/auth/authenticateUser",
  async (userData: string, thunkAPI) => {
    try {
      const response = await authService.authenticate(userData);
      return response;
    } catch (error) {
      console.log(typeof error);
      if (authService.isErrorResponseType(error)) {
        console.log(error.message);
        return thunkAPI.rejectWithValue(JSON.parse(error.message).message);
      } else {
        console.error("Unknown error occurred:", error);
        return thunkAPI.rejectWithValue(`Unknown error occurred: ${error}`);
      }
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user.user = action.payload.user;
        state.user.token = action.payload.token;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null;
      });
  },
});

export const { reset } = authSlice.actions;
export const selectCount = (state: RootState) => state.auth;
export default authSlice.reducer;
